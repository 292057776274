const mode = window.location.hostname;

let MEET_URL = 'meet.jit.si'
let SOCKET_ENDPOINT = 'http://localhost:8000';
let DOMAIN = 'localhost';
let BACKEND_API = 'http://localhost:3000';
let RESOURCES_BUCKET_URL =
'https://admin-panel-resources-dev.s3.ca-central-1.amazonaws.com';
switch(mode){
    case 'conference.vva360.com':
        MEET_URL= 'v2.meet.vva360.com';
        SOCKET_ENDPOINT = 'https://conference.session.app.vva360.com'; // need to change 
        DOMAIN= 'vva360.com';
        BACKEND_API = 'https://admin.api.portal.vva360.com'; //need to change
        RESOURCES_BUCKET_URL =
            'https://admin-panel-resources-prod.s3.ca-central-1.amazonaws.com'; //need to change
        break;
    case 'stage.app.vva360.com':
        MEET_URL= 'dev.v2.meet.vva360.com';
        SOCKET_ENDPOINT = 'https://stage.session.app.vva360.com';
        DOMAIN= 'vva360.com';
        BACKEND_API = 'https://dev.admin.api.vva360.com';
        RESOURCES_BUCKET_URL =
            'https://admin-panel-resources-dev.s3.ca-central-1.amazonaws.com';
        break;
    default:
        MEET_URL= 'dev.v2.meet.vva360.com';
        SOCKET_ENDPOINT = 'http://localhost:8000';
        // SOCKET_ENDPOINT = 'https://stage.session.app.vva360.com';
        DOMAIN= 'localhost';
        BACKEND_API = 'http://localhost:4040';
        // BACKEND_API = 'https://dev.admin.api.vva360.com';
        RESOURCES_BUCKET_URL =
            'https://admin-panel-resources-dev.s3.ca-central-1.amazonaws.com';
}
export {
    MEET_URL,
    SOCKET_ENDPOINT,
    DOMAIN,
    BACKEND_API,
    RESOURCES_BUCKET_URL
}