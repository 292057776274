import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { conferenceActions} from '../slice';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './index.scss';


type Props = {
    hangUpModal: boolean;
    setHangUpModal: Function;
    api:any;
    moderator: boolean
  };
export function HangUpModal(props:Props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {hangUpModal,setHangUpModal, api, moderator} = props;
    const toggle = () => setHangUpModal(!hangUpModal);

    const thankYouPage = () => {
      history.push('/thank-you')
    }
    const leave = () => {
        if(api){
            api.executeCommand('hangup');
            setHangUpModal(false)
            thankYouPage()
        }
    }
    const closeMeeting = () => {
        dispatch(conferenceActions.closeMeeting({}))
        setHangUpModal(false)
    }
    return (
      <Modal className="endMeetingModal" isOpen={hangUpModal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle} >
        <ModalBody>
          <div className="titleRow">
            <h3>End/Leave Meeting</h3>
            <button onClick={toggle}>+</button>
          </div>
          <div className="buttons">
            {moderator && <button className="primary-button" onClick={closeMeeting}>End the Meeting</button>}
            <button className="secondary-button" onClick={leave}>Leave</button>
          </div>
        </ModalBody>
      </Modal>
    )
}