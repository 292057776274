import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { RootState, store } from '../../store/store';
import { notificationActions } from "features/notification/slice";
import { ALERT_TYPES, NOTIFICATION_TYPES } from "features/notification/constants";
import {
  ConferenceState,
  roomStatus,
  GuestRequest,
  Participant,
} from "./types";

import {
  hangupByModerator,
  localUserHangedUp,
} from "./actions";
import { v4 as uuidv4 } from 'uuid';
import { ROLE, User } from '../app/types';
import { GuestData, RepNoteData } from './types'
import { stat } from "fs";
import { DOMAIN,BACKEND_API } from '../../configs';
import { API } from './constants';
import { getRoomData } from '../app/slice';

export const initialState: ConferenceState = {
  loading: false,
  hangedUp: false,
  allowedJoinToRoom: false,
  localAudioMuted: false,
  localVideoMuted: false,
  displayName: "",
  guestRequests: [],
  participants: [],
  isSidebarOpen: false,
  moderatorAudioVideoToggleParticipant: {
    mute: false,
    type: '',
  },
  toggleAudioVideo: {
    mute: false,
    type: '',
  },
  changeDisplayName: {
    newName: '',
    uuid: '',
  },
  changePresenter: {
    jitsiId: '',
    uuid: '',
    enable: false,
  },
  requestToUnmute: {
    uuid: '',
    type: 'audio',
    by: '',
    name: '',
  },
  requestLowerAllHandByModerator: 0,
  requestLowerHandByModerator: 0,
  requestRemoveParticipant: false,
  requestCloseMeeting: false,
  iamToggleRaisedHand: 0,
  isGuestTokenAvailable: false,
  muteAll: 0,

};


export const guestLogin = createAsyncThunk<any, GuestData, { state: RootState }>(
  'conference/guestToken',
  async (guestData, { getState } ) => {

    sessionStorage.setItem(`${DOMAIN}-token`, "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6InRlc3QgdXNlciIsImVtYWlsIjoiYmFoZXJAZ21haWwuY29tIn19LCJhdWQiOiJiYWhlciIsImlzcyI6ImJhaGVyIiwic3ViIjoiYmFoZXIiLCJtb2RlcmF0b3IiOmZhbHNlLCJyb29tIjoiKiJ9.JteG0Qcq4Zo9e_eA1uEdWKd1LUcmdq8MD0Syq--iD0E")
    return true
  }
)

export const updateRepNotes = createAsyncThunk<any, RepNoteData, { state: RootState }>(
  'conference/updateRepNotes',
  async (repNoteData, { dispatch } ) => {
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem(`${DOMAIN}-token`)}`,
        },
      method: API.UPDATE_REP_NOTES.method,
      body: JSON.stringify({
        meetingId: repNoteData.meetingId,
        repNotes: repNoteData.repNotes,
      })
    }
    let response = await fetch(`${BACKEND_API}${API.UPDATE_REP_NOTES.path}`,options);
    if (response) {
      dispatch(notificationActions.noteUpdateSuccess({message:"Note updated succcessfully", alertType:ALERT_TYPES.SUCCESS}));
      return await response.json();
    }
  }
)


export const counterSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {
    joinAttempt(state, action: PayloadAction<string>) {
      state.roomWaitingFor = action.payload;
    },
    joinedAsModerator(state, action: PayloadAction<any>) {
      state.roomWaitingFor = undefined;
      state.roomJoinedTo = action.payload.roomWaitingFor;
      state.allowedJoinToRoom = true;
    },
    toggleLocalAudioMute(state) {
      state.localAudioMuted = !state.localAudioMuted;
    },
    toggleLocalVideoMute(state) {
      state.localVideoMuted = !state.localVideoMuted;
    },
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setDisplayName(state, action: PayloadAction<string>) {
      state.displayName = action.payload;
    },
    joinedAsGuest(state, action: PayloadAction<any>) {
      state.roomWaitingFor = undefined;
      state.roomJoinedTo = action.payload.room;
      state.allowedJoinToRoom = true;
      state.requestRemoveParticipant = false;
    },
    joinAsGuestRequestToModerator(state, action: PayloadAction<GuestRequest>) {
      state.guestRequests = [...state.guestRequests, { ...action.payload }];
    },
    userJoined(state, action: PayloadAction<Participant>) {
      state.participants = [...state.participants, { ...action.payload }];
    },
    userLeft(state, action: PayloadAction<string>) {
      state.participants = state.participants.filter(
        (_participant) => _participant.uuid !== action.payload
      );
    },
    approveGuestJoinRequest(state, action: PayloadAction<any>) {
      state.guestRequests = state.guestRequests.filter(
        (_guest) => _guest.uuid !== action.payload.uuid
      );
    },
    waitingGuestDisconnected(state, action: PayloadAction<string>) {
      state.guestRequests = state.guestRequests.filter(
        (_guest) => _guest.socketId !== action.payload
      );
    },
    cancelGuestJoinRequest(state, action: PayloadAction<any>) {
      state.guestRequests = state.guestRequests.filter(
        (_guest) => _guest.uuid !== action.payload.uuid
      );
    },
    participantJoined(state, action: PayloadAction<any>) {
      const participant = {
        name: action.payload.name,
        socketId: '',
        uuid: '',
        roles: [ROLE.GUEST],
        jitsiId: action.payload.id,
        status: {
          audioMuted: false,
          videoMuted: false,
          handRaised: false,
        }
      }
      let existingParticipant = false
      state.participants = state.participants.map(p => {
        if(p.jitsiId === action.payload.id){
          p.name = action.payload.name
          p.roles = [ROLE.GUEST];
          existingParticipant = true;
        }
        return p;
      })
      if(!existingParticipant){
        state.participants.push(participant)
      }
      
    },
    participantDetail(state, action: PayloadAction<any>) {
      const user: User = action.payload
      
      state.participants = state.participants.map(p => {
        if (p.uuid === action.payload.uuid || p.jitsiId===action.payload.jitsiId) {
          p.name = user.name;
          p.socketId = user.socketId?user.socketId: p.socketId
          p.uuid= user.uuid?user.uuid: p.uuid
          p.roles= user.roles
          p.jitsiId= user.jitsiId?  user.jitsiId: p.jitsiId
          p.status = user.status
        
          if(p.uuid && p.jitsiId && p.roles.includes(ROLE.PRESENTER)){
            state.changePresenter.enable = true
            state.changePresenter.jitsiId = user.jitsiId
            state.changePresenter.uuid = user.uuid
          }
          
          return p;
        }
        return p;
      })
    },
    moderatorRequestAudioVideoToggleParticipant(state, action: PayloadAction<any>) {
    },
    moderatorAudioVideoToggleParticipant(state, action: PayloadAction<any>) {
      state.moderatorAudioVideoToggleParticipant = {
        mute: action.payload.mute,
        type: action.payload.type,
      }
    },
    audioVideoMuteStatusChanged(state, action: PayloadAction<any>) {
    },
    audioVideoToggle(state, action: PayloadAction<any>) {
      state.toggleAudioVideo = {
        mute: action.payload.mute,
        type: action.payload.type,
      }
    },
    changeDisplayName(state, action: PayloadAction<any>) {
      state.changeDisplayName = {
        newName: action.payload.newName,
        uuid: action.payload.uuid,
      }
    },
    participantAudioVideoMuteStatusChanged(state, action: PayloadAction<any>) {
      const { uuid, type, muted } = action.payload
      state.participants = state.participants.map(p => {
        if (p.uuid === uuid) {
          if (type === 'video') {
            p.status.videoMuted = muted;
          } else if (type === 'audio') {
            p.status.audioMuted = muted;
          }
        }
        return p;
      })
    },
    participantNameChanged(state, action: PayloadAction<{ uuid: string, newName: string }>) {
      const { uuid, newName } = action.payload
      state.participants = state.participants.map(p => {
        if (p.uuid === uuid) {
          p.name = newName
        }
        return p;
      })
    },
    participantNameChangedByModerator(state, action: PayloadAction<{ uuid: string, newName: string }>) {
      const { uuid, newName } = action.payload
      state.changeDisplayName.uuid = uuid
      state.changeDisplayName.newName = newName
      state.participants = state.participants.map(p => {
        if (p.uuid === uuid) {
          p.name = newName
        }
        return p;
      })
    },
    toggleMuteAll(state, action: PayloadAction<any>) {

    },
    muteAll(state, action: PayloadAction<any>) {
      state.muteAll = state.muteAll + 1;
    },
    disconnectedParticipant(state, action: PayloadAction<any>) {

      state.guestRequests = state.guestRequests.filter(
        (_guest) => _guest.uuid !== action.payload.uuid
      );
      state.participants = state.participants.filter(
        (_participant) => _participant.uuid !== action.payload
      );
    },
    aboutToParticipantDisconnect(state, action: PayloadAction<any>) {

      // state.guestRequests = state.guestRequests.filter(
      //   (_guest) => _guest.uuid !== action.payload.uuid
      // );
      // state.participants = state.participants.filter(
      //   (_participant) => _participant.uuid !== action.payload
      // );
    },
    raiseHandUpdated(state, action: PayloadAction<any>) {
      const { id, handRaised } = action.payload
      let participantsIndex = 0;
      const addRaisedHandParticipants = state.participants.map((p, index) => {
        if (p.jitsiId === id) {
          p.status.handRaised = handRaised;
          participantsIndex = index;
        }
        return p;
      })

      const chooseParticipant = addRaisedHandParticipants.splice(
        participantsIndex,
        1,
      );
      if (handRaised) {
        addRaisedHandParticipants.splice(0, 0, chooseParticipant[0]);
      } else {
        addRaisedHandParticipants.push(chooseParticipant[0]);
        state.participants = [...addRaisedHandParticipants];
      }
    },
    makePresenter(state, action: PayloadAction<any>) {
      state.changePresenter.enable = action.payload.enable
      state.changePresenter.jitsiId = action.payload.jitsiId
      state.changePresenter.uuid = action.payload.uuid
      state.participants = state.participants.map(p => {
        if (p.jitsiId === action.payload.jitsiId) {
          if (action.payload.enable) {
            if (!p.roles.includes(ROLE.PRESENTER)) {
              p.roles.push(ROLE.PRESENTER)
            }

          } else {
            p.roles = p.roles.filter(r => r !== ROLE.PRESENTER)
          }

        }
        return p;
      })

    },
    informPresenter(state, action: PayloadAction<any>) {
      state.changePresenter.enable = action.payload.enable
      state.changePresenter.jitsiId = action.payload.jitsiId
      state.changePresenter.uuid = action.payload.uuid
      state.participants = state.participants.map(p => {
        if (p.jitsiId === action.payload.jitsiId) {
          if (action.payload.enable) {
            if (!p.roles.includes(ROLE.PRESENTER)) {
              p.roles.push(ROLE.PRESENTER)
            }

          } else {
            p.roles = p.roles.filter(r => r !== ROLE.PRESENTER)
          }

        }
        return p;
      })

    },
    askToUnmute(state, action: PayloadAction<any>) {

    },
    askUnmuteRequest(state, action: PayloadAction<any>) {
      state.requestToUnmute = {
        type: action.payload.type,
        uuid: action.payload.uuid,
        by: action.payload.by,
        name: action.payload.name,
      }
    },
    lowerAllHand(state, action: PayloadAction<any>) {
    },
    lowerAllHandByModerator(state, action: PayloadAction<any>) {
      state.requestLowerAllHandByModerator = state.requestLowerAllHandByModerator + 1
    },
    lowerHandByModerator(state, action: PayloadAction<any>) {
      // state.requestLowerHandByModerator = state.requestLowerHandByModerator + 1
    },
    requestLowerHandByModerator(state, action: PayloadAction<any>) {
      state.requestLowerHandByModerator = state.requestLowerHandByModerator + 1
    },
    removeParticipantByModerator(state, action: PayloadAction<any>) {

    },
    requestRemoveParticipantByModerator(state, action: PayloadAction<any>) {
      state.requestRemoveParticipant = true;
    },
    requestbackToWaitingRoomParticipantByModerator(state, action: PayloadAction<any>) {
      state.allowedJoinToRoom=false;
      state.requestRemoveParticipant = true;
      state.roomWaitingFor=state.roomJoinedTo;
      state.roomJoinedTo=undefined;
      state.isGuestTokenAvailable=false;
    },
    closeMeeting(state, action: PayloadAction<any>) {

    },
    requestCloseMeetingByModerator(state, action: PayloadAction<any>) {
      state.requestCloseMeeting = true;
    },
    participantLeft(state, action: PayloadAction<any>) {
      state.participants = state.participants.filter(p => p.jitsiId !== action.payload.id)
    },
    iamToggleRaisedHand(state, action: PayloadAction<any>) {
      state.iamToggleRaisedHand = state.iamToggleRaisedHand + 1;
    },
    participantBackToWaitingRoom(state,action: PayloadAction<any>){
      state.participants = state.participants.filter(p => p.uuid !== action.payload.uuid);
    }

  },

  extraReducers: (builder) => {
    builder
      .addCase(hangupByModerator, (state) => {
        state.hangedUp = true;
      })
      .addCase(localUserHangedUp, (state) => {
        state.allowedJoinToRoom = false;
      })
      .addCase(guestLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(guestLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isGuestTokenAvailable = true;
      })
      .addCase(updateRepNotes.fulfilled, (state, action) => {
        state.repNotes = action.payload;
      })
  },
});

export const {
  actions: conferenceActions,
  reducer: conferenceReducer,
  name: conferenceSliceKey,
} = counterSlice;
